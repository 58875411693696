import styled from "styled-components";

export const BackButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #ff6d1d;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-family: "SF Pro Text";

  &:hover {
    opacity: 0.8;
  }
`;

export const ValueInput = styled.input`
  text-align: right;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  width: 100%;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    border-color: #888;
    background-color: #f2f2f2;
  }

  &:focus {
    border-color: #ff6d1d;
    background-color: #fff;
    box-shadow: 0 0 3px #ff6d1d;
  }
`;

export const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  transition:
    background-color 0.3s ease,
    opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
  }
`;
