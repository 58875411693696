import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { registrateUser } from "../../data/actions/user";
import { emailRE } from "../../common/regex";
import Input from "../../components/inputField";
import { RadioButton } from "../../components/radioButton";
import { FunctionalButton } from "../../components/functionalButton";
import { getUserEmailFromToken, register } from "../../api/onboarding";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import { useHistory, useParams } from "react-router-dom";

import {
  Content,
  ErrorText,
  FormCard,
  FormContainer,
  FormTitle,
  StyledCheckArea,
  StyledRememberMe,
  StyledSpan,
  StyledSubtext,
  StyledTermText,
} from "./styles";
import { useGoogleLogin } from "@react-oauth/google";
import { API_HOST } from "../../constants";

const uppercaseRE = /(?=.*?[A-Z])/;
const lowercaseRE = /(?=.*?[a-z])/;
const numberRE = /(?=.*?[0-9])/;
const eightMoreRE = /^[\s\S]{8,}$/;
const passwordRE = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const Register = ({ email, setEmail, isMobile }) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const [agree, setAgree] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [editable, setEditable] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { token } = useParams();

  const history = useHistory();

  const googleSignup = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetch(`${API_HOST}/auth/google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: codeResponse.access_token }),
      })
        .then((res) => res.json())
        .then((data) => {
          // email, given_name, family_name
          // TAKE ALL THE ABOVE VALUES AND PASS IT TO THE REGISTER FUNCTION
          const email_from_google = data.data.email;
          const name_from_google = data.data.name;
          // generate random password
          const random_password = Math.random().toString(36).slice(-8);
          register(
            {
              email: email_from_google,
              name: name_from_google,
              company: "",
              password: random_password,
              phone: "",
              googleuser: true,
            },
            () =>
              history.push({
                pathname: "/account/emailed",
                state: { email: email_from_google },
              }),
            (err) => {
              setHasError(true);
            },
          );
        });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const autofillName = urlParams.get("name");
    const autofillCompany = urlParams.get("company");
    const autofillEmail = urlParams.get("email");
    if (autofillName) {
      setName(autofillName);
    }
    if (autofillCompany) {
      setCompany(autofillCompany);
    }
    if (autofillEmail) {
      setEmail(autofillEmail);
    }
  }, []);

  useEffect(() => {
    if (
      emailRE.test(email) &&
      company?.length > 0 &&
      passwordRE.test(password) &&
      name?.length > 0 &&
      agree
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });
  useEffect(() => {
    if (token) {
      getEmailFromToken(token);
    }
  }, []);

  const getEmailFromToken = async (token1) => {
    await getUserEmailFromToken(token1)
      .then((res) => {
        setEmail(res.data.email);
        setCompany(res.data.name);
        setEditable(true);
      })
      .catch((err) => console.log(err.response.data));
  };
  const handleChange = () => {
    setAgree(!agree);
  };

  const handleRegister = () => {
    register(
      { email, name, company, password, phone, googleuser: false },
      () => history.push({ pathname: "/account/emailed", state: { email } }),
      (err) => {
        setHasError(true);
      },
    );
  };

  useEffect(() => {
    setHasError(false);
  }, [email]);

  return (
    <FormContainer isMobile={isMobile}>
      <Content isMobile={isMobile}>
        <FormCard>
          <FormTitle>Sign Up</FormTitle>
          <StyledSubtext>
            Fill in the fields below to create your account
          </StyledSubtext>
          <Input
            label="Full Name"
            type="text"
            placeholder="Full Name"
            labelSize="12px"
            textAreaHeight="48px"
            fontSize="18px"
            marginTop="18px"
            value={name}
            setValue={(val) => setName(val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            // noErrorBorder={true}
          />
          <Input
            label="Company Name"
            type="text"
            placeholder="ABC Company"
            labelSize="12px"
            textAreaHeight="48px"
            fontSize="18px"
            marginTop="18px"
            value={company}
            setValue={(val) => setCompany(val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            // noErrorBorder={true}
          />

          <div
            style={{
              marginTop: "20px",
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <label htmlFor="phone" style={{ fontSize: "12px" }}>
              Phone Number
            </label>
            <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
              <PhoneInput
                id="phone"
                country={"ca"}
                value={phone}
                onChange={(value) => setPhone(value)}
                style={{ marginTop: "16px", width: "100%", height: "100%" }}
                inputStyle={{
                  fontSize: "18px",
                  padding: "8px",
                  lineHeight: "24px",
                  marginLeft: "20px",
                  paddingLeft: "28px",
                  height: "100%",
                  width: "96%",
                  marginRight: "0px",
                }}
                dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
              />
            </div>
          </div>

          <Input
            label="Your Email"
            type="text"
            placeholder="name@company.com"
            labelSize="12px"
            textAreaHeight="48px"
            fontSize="18px"
            marginTop="18px"
            value={email}
            setValue={(val) => setEmail(val)}
            validateFunc={(item) => emailRE.test(item)}
            hasError={hasError}
            noCheckIcon={true}
            // noErrorBorder={!hasError}
            disabled={editable}
          />
          {hasError && (
            <ErrorText>
              Oops! It looks like this username has already been used.
            </ErrorText>
          )}
          <Input
            autoComplete="new-password"
            label="Password"
            type="password"
            placeholder="Password"
            labelSize="12px"
            textAreaHeight="48px"
            fontSize="18px"
            marginTop="18px"
            value={password}
            setValue={(val) => setPassword(val)}
            validateFunc={(item) => passwordRE.test(item)}
            hasError={password.length > 0 && !passwordRE.test(password)}
            noCheckIcon={true}
            // noErrorBorder={true}
          />
          {password.length > 0 && !uppercaseRE.test(password) && (
            <ErrorText>At least 1 uppercase letter should exist.</ErrorText>
          )}
          {password.length > 0 && !lowercaseRE.test(password) && (
            <ErrorText>At least 1 lowercase letter should exist.</ErrorText>
          )}
          {password.length > 0 && !numberRE.test(password) && (
            <ErrorText>At least 1 digit should exist.</ErrorText>
          )}
          {password.length > 0 && !eightMoreRE.test(password) && (
            <ErrorText>Should contain at least 8 characters.</ErrorText>
          )}
          <StyledCheckArea>
            <StyledRememberMe
              control={
                <RadioButton isChecked={agree} handleChange={handleChange} />
              }
            />
            <StyledTermText>
              I agree to the
              <a href="https://pledgx.com/terms-of-use.html" target="_blank">
                <StyledSpan> Terms of Use </StyledSpan>
              </a>
              and
              <a href="https://pledgx.com/privacy-policy.html" target="_blank">
                <StyledSpan> Privacy Policy </StyledSpan>
              </a>
            </StyledTermText>
          </StyledCheckArea>
          <FunctionalButton
            buttonTitle="Sign Up"
            handleButton={handleRegister}
            width="100%"
            marginRight="0px"
            disabled={disabled && !hasError}
          />
          <StyledSubtext style={{ textAlign: "center", marginBottom: "10px" }}>
            or
          </StyledSubtext>
          <FunctionalButton
            buttonTitle="Sign up with Google"
            handleButton={() => googleSignup()}
            width="100%"
            marginRight="0px"
          />
          <StyledSubtext>
            Already have an account?
            <StyledSpan onClick={() => history.push("/account/login")}>
              {" "}
              Login
            </StyledSpan>
          </StyledSubtext>
        </FormCard>
      </Content>
    </FormContainer>
  );
};
