import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import ConciseTable from "../../../components/filesListTable/conciseTable";
import { FunctionalButton } from "../../../components/functionalButton/index";
import {
  StyledReportSelectionContainer,
  HeaderArea,
  LargeReportText,
  SectionText,
  FieldArea,
} from "../styles";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import {
  getAllPartnerReferralReport,
  getAllIssueStatusReport,
  getAllInstitutionReport,
  getAllProspectUsersReport
} from "../../../api/external_data";
import updateObjectByPath from "../../../common/updateObjectByPath";
import Select from "../../../components/selectField";
import Box from "@mui/material/Box";
import { ReactComponent as ViewIcon } from "../../../assets/common/ViewIcon.svg";
import { StyledExpiredContainer, StyledLiveContainer } from "../styles";
import ShowImagePopup from "../../../components/showImagePopup/index";
import { getFilterUsersList } from "../../../data/actions/dataMaintenance.js";
import { useDispatch, useSelector } from "react-redux";
import { SubmitErrorMessage } from "./submitErrorMessage";
import DateTimePickerField from "components/dateTimePickerField";

export const Reports = (editFunc, deleteFunc, isMobile) => {
  const headers = [
    { title: "Id", width: "5%" },
    { title: "Email", width: "35%" },
    { title: "Institution Name", width: "12%" },
    { title: "Status", width: "8%" },
    { title: "Onboarding Step", width: "12%" },
    { title: "Onboarding Date/Time", width: "17%" },
    { title: "Last Login Date/Time", width: "18%" },
  ];

  const headersInstitution = [
    { title: "Id", width: "10%" },
    { title: "Institution Name", width: "15%" },
    { title: "Number of Personnel", width: "15%" },
    { title: "Number of Projects", width: "14%" },
    { title: "Number of Users", width: "12%" },
    { title: "Plan Name", width: "18%" },
    { title: "Expiry Date/Time", width: "18%" },
  ];

  const headersIssue = [
    { title: "Id", width: "10%" },
    { title: "Email", width: "25%" },
    { title: "Name", width: "15%" },
    { title: "Issue Number", width: "18%" },
    { title: "Description", width: "18%" },
    { title: "Issue Created", width: "20%" },
    { title: "Screenshot", width: "10%" },
  ];

  const headersPartnerReferral = [
    { title: "Id", width: "5%" },
    { title: "Full Name", width: "15%" },
    { title: "Email", width: "25%" },
    { title: "Company Name", width: "15%" },
    { title: "Company Address", width: "15%" },
    { title: "Services", width: "8%" },
    { title: "Referred Date", width: "8%" },
    { title: "Referred To", width: "12%" }
  ];

  const headersProspectUsers = [
    { title: "Id", width: "5%" },
    { title: "Full Name", width: "15%" },
    { title: "Email", width: "20%" },
    { title: "Company Name", width: "20%" },
    { title: "Address", width: "10%" },
    { title: "Updated Date", width: "15%" },
    { title: "Confirmed?", width: "10%" }
  ];

  const [query, setQuery] = useState("");
  const [data, setData] = useState({});
  const [activeCounter, setActiveCounter] = useState(0);
  const [inactiveCounter, setInactiveCounter] = useState(0);
  const [issueStatusReport, setIssueStatusReport] = useState([]);
  const [queryPartnerReferral, setQueryPartnerReferral] = useState("");
  const [partnerReferralReport, setPartnerReferralReport] = useState([]);
  const [internalSearchPartnerReferralResults, setInternalPartnerReferralResults] =
    useState(partnerReferralReport);
  const [queryProspectUsers, setQueryProspectUsers] = useState("");
  const [prospectUsersReport, setProspectUsersReport] = useState([]);
  const [internalSearchProspectUsersResults, setInternalProspectUsersResults] =
    useState(prospectUsersReport);
  const [internalSearchIssueResults, setInternalSearchIssueResults] =
    useState(issueStatusReport);
  const [queryIssue, setQueryIssue] = useState("");
  const [selectOption, setSelectOption] = useState("");
  const [institutionReport, setInstitutionReport] = useState([]);
  const [
    internalSearchInstitutionResults,
    setInternalSearchInstitutionResults,
  ] = useState(institutionReport);
  const [queryInstitution, setQueryInstitution] = useState("");
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [newImageId, setNewImageId] = useState(null);
  const [fiterUserList, setFilterUserList] = useState([]);
  const [
    internalFilterAllUsersSearchResults,
    setInternalFilterAllUsersSearchResults,
  ] = useState(fiterUserList);

  const dispatch = useDispatch();
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isFilterAllUsers, setIsFilterAllUsers] = useState(false);
  const fileOptions = ["All Users", "All Institutions", "All Partner Referral", "All Issues", "All Prospect Users"];

  useEffect(() => {
    getAllIssueStatusReport()
      .then((res) => {
        setIssueStatusReport(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  useEffect(() => {
    getAllPartnerReferralReport()
      .then((res) => {
        setPartnerReferralReport(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  useEffect(() => {
    getAllProspectUsersReport()
      .then((res) => {
        setProspectUsersReport(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  useEffect(() => {
    getAllInstitutionReport()
      .then((res) => {
        setInstitutionReport(res);
        setIsFilterAllUsers(true);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleFilterUserListReport = () => {
    dispatch(
      getFilterUsersList(
        data,
        (res) => {
          setFilterUserList(res.data);
          setActiveCounter(res.active_user);
          setInactiveCounter(res.inactive_user);
        },
        (err) => {
          const errorMessage = [];
          errorMessage.push(err.response.data.message);
          if (errorMessage.length > 0) {
            setErrors(errorMessage);
            setOpenErrorPopup(true);
          }
        }
      )
    );
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const showImage = (imageId) => {
    setShowImagePopup(true);
    setNewImageId(imageId);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="5%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="20%">
          {bodyData.email}
          {bodyData.user_role_id !== 1 ? (
            ""
          ) : bodyData.user_role_id === 1 ? (
            <b>(Admin)</b>
          ) : (
            ""
          )}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.institution} <b>({bodyData.user_type_name})</b>
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.status === "inactive" ? (
            <StyledExpiredContainer>Inactive</StyledExpiredContainer>
          ) : bodyData.status === "active" ? (
            <StyledLiveContainer>Active</StyledLiveContainer>
          ) : (
            ""
          )}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.onboarding_step}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="17%">
          {bodyData.onboarding_date_time}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="18%">
          {bodyData.last_date_time_signed_in}
        </StyledTableCell>
      </>
    );
  };

  const bodyInstitution = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="20%">
          {bodyData.institution} <b>({bodyData.user_type_name})</b>
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.number_of_personnel}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.number_of_projects}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.number_of_users}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="20%">
          {bodyData.user_access?.package_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="20%">
          {bodyData.user_access?.subscription_expiry}
        </StyledTableCell>
      </>
    );
  };


  const bodyPartnerReferral = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="5%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="12%">
          {bodyData.full_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.email}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.company_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="18%">
          {bodyData.company_address}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.services}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.requested_date}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.partners_referred_to}
        </StyledTableCell>
      </>
    );
  };

  const bodyProspectUsers = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="5%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="12%">
          {bodyData.full_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.email}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.company_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="18%">
          {bodyData.company_address}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.updated_at}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.is_confirmed === 0 ? (
            <StyledExpiredContainer>No</StyledExpiredContainer>
          ) : bodyData.is_confirmed === 1 ? (
            <StyledLiveContainer>Yes</StyledLiveContainer>
          ) : (
            ""
          )}
        </StyledTableCell>
      </>
    );
  };

  const bodyIssue = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="5%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.email}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.first_name + " " + bodyData.last_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.issue_report_data?.issue_number}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="20%">
          {bodyData.issue_report_data?.description}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="15%">
          {bodyData.issue_report_data?.created_at}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="10%">
          {bodyData.issue_report_data?.screenshot_attachment && (
            <ViewIcon
              onClick={() =>
                showImage(bodyData.issue_report_data?.screenshot_attachment)
              }
            />
          )}
        </StyledTableCell>
      </>
    );
  };

  return (
    <StyledReportSelectionContainer isMobile={isMobile}>
      <SectionText>Select Report</SectionText>
      <div className="fullWidthInputWrap">
        <Select
          style={{ marginRight: "16px" }}
          label="Report*"
          options={fileOptions?.map((item) => ({
            value: item,
            label: item,
          }))}
          value={data?.select_report}
          setValue={(val) => {
            onChangeData("select_report", val);
            setSelectOption(val);
          }}
          validateFunc={(item) => Boolean(item)}
          noCheckIcon={true}
          showDropdownIndicator={true}
        />
      </div>

      {selectOption === "All Users" && (
        <>
          <FieldArea style={{ justifyContent: "flex-start" }}>
            <SectionText>Filter Report</SectionText>

            <div
              style={{
                display: "flex",
                flexDirection: "row",

                width: "50%",
              }}
            >
              <DateTimePickerField
                showDateField={true}
                dateWidth="100%"
                paddingRight="16px"
                dateLabel="From"
                dateValue={data?.from_date ? data?.from_date : ""}
                setDateValue={(val) => {
                  onChangeData("from_date", val);
                }}
              />
              <DateTimePickerField
                showDateField={true}
                dateWidth="100%"
                dateLabel="To"
                dateValue={data?.to_date ? data?.to_date : ""}
                setDateValue={(val) => {
                  onChangeData("to_date", val);
                }}
              />
            </div>
          </FieldArea>
          <br />

          <FunctionalButton
            buttonTitle="View Report"
            handleButton={handleFilterUserListReport}
            width="20%"
          />

          <SubmitErrorMessage
            isMobile={isMobile}
            errors={errors}
            openPopup={openErrorPopup}
            setOpenPopup={setOpenErrorPopup}
          />
        </>
      )}

      <br />
      <br />

      {selectOption === "All Users" && isFilterAllUsers === true && (
        <>
          <div className="fullWidthInputWrap">
            <HeaderArea width="100%">
              <SearchBar
                filteredData={fiterUserList}
                setInternalSearchResults={
                  setInternalFilterAllUsersSearchResults
                }
                query={query}
                setQuery={setQuery}
                internalSearch={true}
              />
              <LargeReportText>
                Active{" "}
                <Box
                  sx={{
                    p: 0.1,
                    border: "1px dashed grey",
                    fontSize: "14px",
                    color: "#FF6D1D",
                    marginLeft: "4px",
                    marginRight: "4px",
                  }}
                >
                  {" "}
                  {activeCounter}{" "}
                </Box>
                Inactive{" "}
                <Box
                  sx={{
                    p: 0.1,
                    border: "1px dashed grey",
                    fontSize: "14px",
                    color: "#FF6D1D",
                    marginLeft: "4px",
                    marginRight: "4px",
                  }}
                >
                  {" "}
                  {inactiveCounter}
                </Box>
              </LargeReportText>
            </HeaderArea>
          </div>

          <ConciseTable headers={isMobile ? "" : headers} />

          <ConciseTable
            length={isMobile ? 5 : 5.5}
            body={body}
            data={
              query && query !== ""
                ? internalFilterAllUsersSearchResults
                : fiterUserList
            }
          />
        </>
      )}

      {selectOption === "All Institutions" && (
        <>
          <div className="fullWidthInputWrap">
            <HeaderArea width="98%">
              <SearchBar
                filteredData={institutionReport}
                setInternalSearchResults={setInternalSearchInstitutionResults}
                query={queryInstitution}
                setQuery={setQueryInstitution}
                internalSearch={true}
              />
            </HeaderArea>
          </div>

          <ConciseTable headers={isMobile ? "" : headersInstitution} />

          <ConciseTable
            length={isMobile ? 5 : 5.5}
            body={bodyInstitution}
            data={
              queryInstitution && queryInstitution !== ""
                ? internalSearchInstitutionResults
                : institutionReport
            }
          />
        </>
      )}


      {selectOption === "All Partner Referral" && (
        <>
          <div className="fullWidthInputWrap">
            <HeaderArea width="100%">
              <SearchBar
                filteredData={partnerReferralReport}
                setInternalSearchResults={setInternalPartnerReferralResults}
                query={queryPartnerReferral}
                setQuery={setQueryPartnerReferral}
                internalSearch={true}
              />
            </HeaderArea>
          </div>

          <ConciseTable headers={isMobile ? "" : headersPartnerReferral} />

          <ConciseTable
            length={isMobile ? 5 : 5.5}
            body={bodyPartnerReferral}
            data={
              queryPartnerReferral && queryPartnerReferral !== ""
                ? internalSearchPartnerReferralResults
                : partnerReferralReport
            }
          />
        </>
      )}

      {selectOption === "All Prospect Users" && (
        <>
          <div className="fullWidthInputWrap">
            <HeaderArea width="100%">
              <SearchBar
                filteredData={prospectUsersReport}
                setInternalSearchResults={setInternalProspectUsersResults}
                query={queryProspectUsers}
                setQuery={setQueryProspectUsers}
                internalSearch={true}
              />
            </HeaderArea>
          </div>

          <ConciseTable headers={isMobile ? "" : headersProspectUsers} />

          <ConciseTable
            length={isMobile ? 5 : 5.5}
            body={bodyProspectUsers}
            data={
              queryProspectUsers && queryProspectUsers !== ""
                ? internalSearchProspectUsersResults
                : prospectUsersReport
            }
          />
        </>
      )}


      {selectOption === "All Issues" && (
        <>
          <div className="fullWidthInputWrap">
            <HeaderArea width="100%">
              <SearchBar
                filteredData={issueStatusReport}
                setInternalSearchResults={setInternalSearchIssueResults}
                query={queryIssue}
                setQuery={setQueryIssue}
                internalSearch={true}
              />
            </HeaderArea>
          </div>

          <ConciseTable headers={isMobile ? "" : headersIssue} />

          <ConciseTable
            length={isMobile ? 5 : 5.5}
            body={bodyIssue}
            data={
              queryIssue && queryIssue !== ""
                ? internalSearchIssueResults
                : issueStatusReport
            }
          />

          <ShowImagePopup
            openPopup={showImagePopup}
            setOpenPopup={setShowImagePopup}
            imageId={newImageId}
          />
        </>
      )}
    </StyledReportSelectionContainer>
  );
};
