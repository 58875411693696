import { ScreenRoot } from "../portfolioInsights/styles";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  DeleteButton,
  FunctionButtonContainer,
  HeaderContainer,
  StatusSpan,
  StyledButton,
  StyledTable,
  TabButton,
  TabButtonSpan,
  TabContainer,
  VerticalContainer,
} from "./styles";
import { LargeText, MediumText } from "../styles";
import {
  getUnderwriterIdpActiveDocuments,
  getUnderwriterIdpArchivedDocuments,
} from "../../../data/actions/underwriters";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ViewIcon } from "../../../assets/common/ViewIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/common/deleteIcon.svg";
import {
  underwriterIdpActiveDocumentsSelector,
  underwriterIdpArchivedDocumentsSelector,
} from "../../../data/selectors/underwriters";
import { useHistory, useLocation } from "react-router";
import {
  deleteFileFromIdpClientDocuments,
  getParserInputData,
  getPdfDocsFromBucket,
} from "api/external_data.js";
import { ViewFileButton } from "./uploader/styles";
import { Popup } from "../../../components/popup";
import { CloseButton } from "../../../components/closeButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/filesListTable/styles";

export default function IDPDocuments() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  let history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAutomatedFlow = queryParams.get("automated") === "true";

  const [selectedTab, setSelectedTab] = useState("pending");
  const [activeDocuments, setActiveDocuments] = useState([]);
  const [archivedDocuments, setArchivedDocuments] = useState([]);
  const [pdf, setPdf] = useState({
    fileName: "",
    data: null,
  });

  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [query, setQuery] = useState();

  const [loadingPdf, setLoadingPdf] = useState(false);

  const underwriterIdpActiveDocumentsData = useSelector(
    underwriterIdpActiveDocumentsSelector,
    (prev, next) => prev.length === next.length,
  );
  const underwriterIdpArchivedDocumentsData = useSelector(
    underwriterIdpArchivedDocumentsSelector,
    (prev, next) => prev.length === next.length,
  );

  useEffect(() => {
    if (underwriterIdpActiveDocumentsData.length < 1) {
      dispatch(getUnderwriterIdpActiveDocuments());
    }
    if (underwriterIdpArchivedDocumentsData.length < 1) {
      dispatch(getUnderwriterIdpArchivedDocuments());
    }
    if (Array.isArray(underwriterIdpActiveDocumentsData)) {
      underwriterIdpActiveDocumentsData?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setActiveDocuments(underwriterIdpActiveDocumentsData);
      // getDocuments(underwriterIdpActiveDocumentsData);
    } else {
      setActiveDocuments([]);
    }
    if (Array.isArray(underwriterIdpArchivedDocumentsData)) {
      underwriterIdpArchivedDocumentsData?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setArchivedDocuments(underwriterIdpArchivedDocumentsData);
    } else {
      setArchivedDocuments([]);
    }
  }, [underwriterIdpActiveDocumentsData, underwriterIdpArchivedDocumentsData]);

  const handleProceed = () => {
    if (!isAutomatedFlow) {
      history.push("/underwriter/idpdocuments/uploader");
    } else {
      history.push("/underwriter/idpdocuments/uploader?automated=true");
    }
  };

  const handleViewFile = async (data, fileName) => {
    setOpenPdfViewer(true);
    setLoadingPdf(true);

    const pdfBlobs = await getPdfDocsFromBucket(data);

    console.log("pdf blob ", pdfBlobs);

    const pdf = pdfBlobs.find((pdf) => pdf.fileName === fileName);

    if (pdf) {
      const pdfUrl = URL.createObjectURL(
        new Blob([pdf.blob], { type: "application/pdf" }),
      );

      setPdf({
        data: pdfUrl,
        fileName,
      });
    } else {
      setPdf(null);
      console.log(
        "couldn't load the PDF because there wasn't a matching one in the bucket. ",
      );
    }

    setLoadingPdf(false);
  };

  const getBeforeSecondSlash = (input) => {
    const firstSlashIndex = input.indexOf("/");
    const secondSlashIndex = input.indexOf("/", firstSlashIndex + 1);

    return secondSlashIndex !== -1
      ? input.substring(0, secondSlashIndex)
      : input;
  };

  const deleteDocument = async (fileInfo, fileLocation, accountId) => {
    const deleteData = {
      account_id: accountId,
      delete_file_location: fileLocation,
      file_info_array: fileInfo,
    };
    await deleteFileFromIdpClientDocuments(
      deleteData,
      (response) => {
        dispatch(getUnderwriterIdpActiveDocuments());
        dispatch(getUnderwriterIdpArchivedDocuments());
      },
      (error) => {
        dispatch(getUnderwriterIdpActiveDocuments());
        dispatch(getUnderwriterIdpArchivedDocuments());
      },
    );
  };

  const fetchAndNavigateToParser = async (
    client_documents_id,
    account_id,
    classifier_threshold,
    account_name,
  ) => {
    await getParserInputData(
      {
        account_id,
        client_documents_id,
      },
      (response) => {
        history.push({
          pathname: "/underwriter/idpdocuments/parser",
          state: {
            selectedAccount: {
              AccountId: account_id,
              CompanyName: account_name,
              ClassifierThreshold: classifier_threshold,
            },
            clientDocumentsId: client_documents_id,
            splitFiles: response[0].files,
            oldDocument: true,
          },
        });
      },
      (error) => {
        console.log("Could not get parser input data. ", error);
      },
    );
  };

  useEffect(() => {
    console.log("Active documents updated:", activeDocuments);
  }, [activeDocuments]);

  return (
    <>
      <ScreenRoot isMobile={isMobile}>
        <VerticalContainer>
          <HeaderContainer>
            <div>
              <LargeText>Document List</LargeText>
            </div>
            <SearchBar
              filteredData={
                selectedTab === "pending"
                  ? underwriterIdpActiveDocumentsData
                  : underwriterIdpArchivedDocumentsData
              }
              setInternalSearchResults={
                selectedTab === "pending"
                  ? setActiveDocuments
                  : setArchivedDocuments
              }
              query={query}
              setQuery={setQuery}
              internalSearch={true}
              searchWidth="50%"
              searchPlaceHolder="Search by Account ID, Account Name, Document Name, Date Uploaded and IDP Status"
            />
            <StyledButton onClick={handleProceed}>Upload New</StyledButton>
          </HeaderContainer>
          {!isAutomatedFlow ? (
            <>
              <TabContainer>
                <TabButton
                  active={selectedTab === "pending"}
                  onClick={() => setSelectedTab("pending")}
                >
                  Pending
                  <TabButtonSpan> ({activeDocuments.length})</TabButtonSpan>
                </TabButton>
                <TabButton
                  active={selectedTab === "complete"}
                  onClick={() => setSelectedTab("complete")}
                >
                  Complete
                  <TabButtonSpan> ({archivedDocuments.length})</TabButtonSpan>
                </TabButton>
              </TabContainer>
              <StyledTable>
                <StyledTableRow header={true}>
                  <StyledTableCell header={true}>Account ID</StyledTableCell>
                  <StyledTableCell header={true}>Account Name</StyledTableCell>
                  <StyledTableCell header={true}>Document Name</StyledTableCell>
                  <StyledTableCell header={true}>Date Uploaded</StyledTableCell>
                  <StyledTableCell header={true}>IDP Status</StyledTableCell>
                  <StyledTableCell header={true}>View</StyledTableCell>
                </StyledTableRow>
                {selectedTab === "pending" &&
                  activeDocuments.map(
                    (
                      {
                        account_id,
                        account_name,
                        file_info,
                        created_at,
                        idp_status,
                        client_documents_id,
                        classifier_threshold,
                      },
                      index,
                    ) => {
                      const parsedFileInfo = JSON.parse(file_info);

                      return parsedFileInfo.map((file) => (
                        <StyledTableRow key={`${account_id}-${file.fileId}`}>
                          <StyledTableCell>{account_id}</StyledTableCell>
                          <StyledTableCell>{account_name}</StyledTableCell>
                          <StyledTableCell
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                            }}
                          >
                            {file.fileName}
                          </StyledTableCell>
                          <StyledTableCell>
                            {new Date(created_at).toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell>
                            <StatusSpan
                              onClick={() => {
                                if (idp_status === "Splitter") {
                                  history.push({
                                    pathname:
                                      "/underwriter/idpdocuments/splitter",
                                    state: {
                                      selectedAccount: {
                                        AccountId: account_id,
                                        CompanyName: account_name,
                                      },
                                      clientDocumentsId: client_documents_id,
                                      filePath: getBeforeSecondSlash(
                                        file.fileLocation,
                                      ),
                                      bucketName: file.bucketName,
                                    },
                                  });
                                } else if (idp_status === "Classifier") {
                                  history.push({
                                    pathname:
                                      "/underwriter/idpdocuments/classifier",
                                    state: {
                                      selectedAccount: {
                                        AccountId: account_id,
                                        CompanyName: account_name,
                                        ClassifierThreshold:
                                          classifier_threshold,
                                      },
                                      clientDocumentsId: client_documents_id,
                                    },
                                  });
                                } else if (idp_status === "Parser") {
                                  fetchAndNavigateToParser(
                                    client_documents_id,
                                    account_id,
                                    classifier_threshold,
                                    account_name,
                                  );
                                }
                              }}
                              status={idp_status}
                            >
                              {idp_status}
                            </StatusSpan>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FunctionButtonContainer>
                              <ViewFileButton
                                onClick={() =>
                                  handleViewFile(
                                    {
                                      filePath: getBeforeSecondSlash(
                                        file.fileLocation,
                                      ),
                                      bucketName: file.bucketName,
                                    },
                                    file.fileName,
                                  )
                                }
                              >
                                <ViewIcon />
                              </ViewFileButton>
                              <DeleteButton
                                onClick={() => {
                                  setActiveDocuments((prevDocuments) =>
                                    prevDocuments.filter(
                                      (doc) =>
                                        !JSON.parse(doc.file_info).some(
                                          (f) => f.fileId === file.fileId,
                                        ),
                                    ),
                                  );

                                  deleteDocument(
                                    file_info,
                                    file.fileLocation,
                                    account_id,
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </DeleteButton>
                            </FunctionButtonContainer>
                          </StyledTableCell>
                        </StyledTableRow>
                      ));
                    },
                  )}
                {selectedTab === "complete" &&
                  archivedDocuments.map(
                    (
                      {
                        account_id,
                        account_name,
                        file_info,
                        created_at,
                        idp_status,
                        client_documents_id,
                        classifier_threshold,
                        labeller_data,
                      },
                      index,
                    ) => {
                      const parsedFileInfo = JSON.parse(file_info);

                      return parsedFileInfo.map((file) => (
                        <StyledTableRow
                          key={`${account_id}-${file.fileId || file}`}
                        >
                          <StyledTableCell>{account_id}</StyledTableCell>
                          <StyledTableCell>{account_name}</StyledTableCell>
                          <StyledTableCell
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                            }}
                            onClick={() =>
                              handleViewFile(
                                {
                                  filePath: getBeforeSecondSlash(
                                    file.fileLocation,
                                  ),
                                  bucketName: file.bucketName,
                                },
                                file.fileName,
                              )
                            }
                          >
                            {file.fileName || file}
                          </StyledTableCell>
                          <StyledTableCell>
                            {new Date(created_at).toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell>
                            <StatusSpan
                              onClick={() => {
                                history.push({
                                  pathname: "/underwriter/idpdocuments/parser",
                                  state: {
                                    selectedAccount: {
                                      AccountId: account_id,
                                      CompanyName: account_name,
                                      ClassifierThreshold: classifier_threshold,
                                    },
                                    clientDocumentsId: client_documents_id,
                                    splitFiles: labeller_data[0].files,
                                    oldDocument: true,
                                  },
                                });
                              }}
                              status={idp_status}
                            >
                              {idp_status}
                            </StatusSpan>
                          </StyledTableCell>
                          <FunctionButtonContainer>
                            <ViewFileButton
                              onClick={() =>
                                handleViewFile(
                                  {
                                    filePath: getBeforeSecondSlash(
                                      file.fileLocation,
                                    ),
                                    bucketName: file.bucketName,
                                  },
                                  file.fileName,
                                )
                              }
                            >
                              <ViewIcon />
                            </ViewFileButton>
                            <DeleteButton
                              onClick={() => {
                                setArchivedDocuments((prevDocuments) =>
                                  prevDocuments.filter((doc, i) => i !== index),
                                );

                                deleteDocument(
                                  file_info,
                                  file.fileLocation,
                                  account_id,
                                );
                              }}
                            >
                              <DeleteIcon />
                            </DeleteButton>
                          </FunctionButtonContainer>
                        </StyledTableRow>
                      ));
                    },
                  )}
              </StyledTable>
            </>
          ) : (
            <StyledTable>
              <StyledTableRow header={true}>
                <StyledTableCell header={true}>Account ID</StyledTableCell>
                <StyledTableCell header={true}>Account Name</StyledTableCell>
                <StyledTableCell header={true}>Document Name</StyledTableCell>
                <StyledTableCell header={true}>Date Uploaded</StyledTableCell>
                <StyledTableCell header={true}>IDP Status</StyledTableCell>
                <StyledTableCell header={true}>View</StyledTableCell>
              </StyledTableRow>
              {activeDocuments.map(
                ({
                  account_id,
                  account_name,
                  file_info,
                  created_at,
                  idp_status,
                  client_documents_id,
                  classifier_threshold,
                }) => {
                  const parsedFileInfo = JSON.parse(file_info);

                  return parsedFileInfo.map((file) => (
                    <StyledTableRow
                      key={`${account_id}-${file.fileId}`}
                      onClick={() => {
                        if (idp_status === "Splitter") {
                          history.push({
                            pathname: "/underwriter/idpdocuments/splitter",
                            state: {
                              selectedAccount: {
                                AccountId: account_id,
                                CompanyName: account_name,
                              },
                              clientDocumentsId: client_documents_id,
                              filePath: getBeforeSecondSlash(file.fileLocation),
                              bucketName: file.bucketName,
                            },
                          });
                        } else if (idp_status === "Classifier") {
                          history.push({
                            pathname: "/underwriter/idpdocuments/classifier",
                            state: {
                              selectedAccount: {
                                AccountId: account_id,
                                CompanyName: account_name,
                                ClassifierThreshold: classifier_threshold,
                              },
                              clientDocumentsId: client_documents_id,
                            },
                          });
                        } else if (idp_status === "Parser") {
                          history.push({
                            pathname: "/underwriter/idpdocuments/parser",
                            state: {
                              selectedAccount: {
                                AccountId: account_id,
                                CompanyName: account_name,
                                ClassifierThreshold: classifier_threshold,
                              },
                              clientDocumentsId: client_documents_id,
                              splitFilesCount: 1,
                              oldDocument: true,
                            },
                          });
                        }
                      }}
                    >
                      <StyledTableCell>{account_id}</StyledTableCell>
                      <StyledTableCell>{account_name}</StyledTableCell>
                      <StyledTableCell
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                          whiteSpace: "normal",
                        }}
                      >
                        {file.fileName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        <StatusSpan
                          onClick={() => {
                            if (idp_status === "Splitter") {
                              history.push({
                                pathname: "/underwriter/idpdocuments/splitter",
                                state: {
                                  selectedAccount: {
                                    AccountId: account_id,
                                    CompanyName: account_name,
                                  },
                                  clientDocumentsId: client_documents_id,
                                  filePath: getBeforeSecondSlash(
                                    file.fileLocation,
                                  ),
                                  bucketName: file.bucketName,
                                },
                              });
                            } else if (idp_status === "Classifier") {
                              history.push({
                                pathname:
                                  "/underwriter/idpdocuments/classifier",
                                state: {
                                  selectedAccount: {
                                    AccountId: account_id,
                                    CompanyName: account_name,
                                    ClassifierThreshold: classifier_threshold,
                                  },
                                  clientDocumentsId: client_documents_id,
                                },
                              });
                            } else if (idp_status === "Parser") {
                              history.push({
                                pathname: "/underwriter/idpdocuments/parser",
                                state: {
                                  selectedAccount: {
                                    AccountId: account_id,
                                    CompanyName: account_name,
                                    ClassifierThreshold: classifier_threshold,
                                  },
                                  splitFilesCount: 1,
                                  oldDocument: true,
                                },
                              });
                            }
                          }}
                          status={idp_status}
                        >
                          {idp_status}
                        </StatusSpan>
                      </StyledTableCell>
                      <StyledTableCell>
                        <FunctionButtonContainer>
                          <ViewFileButton
                            onClick={() =>
                              handleViewFile(
                                {
                                  filePath: getBeforeSecondSlash(
                                    file.fileLocation,
                                  ),
                                  bucketName: file.bucketName,
                                },
                                file.fileName,
                              )
                            }
                          >
                            <ViewIcon />
                          </ViewFileButton>
                          <DeleteButton
                            onClick={() =>
                              deleteDocument(
                                file_info,
                                file.fileLocation,
                                account_id,
                              )
                            }
                          >
                            <DeleteIcon />
                          </DeleteButton>
                        </FunctionButtonContainer>
                      </StyledTableCell>
                    </StyledTableRow>
                  ));
                },
              )}
            </StyledTable>
          )}
        </VerticalContainer>
      </ScreenRoot>
      <Popup
        dialogContent={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "0",
            }}
          >
            <div style={{ flex: "0 0 auto" }}>
              <CloseButton
                handleClose={() => {
                  setPdf({ fileName: "", data: null });
                  setOpenPdfViewer(false);
                }}
              />
            </div>
            {loadingPdf ? (
              <MediumText>Loading PDF...</MediumText>
            ) : (
              <>
                {pdf ? (
                  <>
                    <MediumText>{pdf.fileName}</MediumText>
                    <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
                      <iframe
                        src={pdf.data}
                        style={{
                          width: "100%",
                          height: "596px",
                          border: "none",
                        }}
                        title={pdf.fileName}
                      />
                    </div>
                  </>
                ) : (
                  <MediumText>The PDF could not be found.</MediumText>
                )}
              </>
            )}
          </div>
        }
        height={"732px"}
        innerHeight={"732px"}
        innerPadding={"28px"}
        margin={"0"}
        open={openPdfViewer}
        setOpen={setOpenPdfViewer}
        width={"690px"}
      />
    </>
  );
}
