import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "pages/underwriters/components/allClientsTable/styles.js";
import { generatePGA } from "../../../../api/external_data";
import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import { useHistory } from "react-router";
import { SuccessPopup } from "./SuccessPopup";
import { AlertMessage } from "./alertMessage";

export const ActionsMenuForPGA = ({ client_id, companyName, status, setData, viewPeerGroupAnalysis }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [notSuccessPopup, setNotSuccessPopup] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const viewPeerGroupAnalysisClientData = () => {
    history.push("/underwriter/client/peergroupanalysis", {
      company_name: companyName,
      account_id: client_id
    });
  };


  const generatePeerGroupAnalysisClientData = () => {
    const data = {
      company_name: companyName,
      account_id: client_id
    };
    generatePGA(data, onSuccessPGAData, onFailurPGAData);
  };


  const onSuccessPGAData = (res) => {
    setOpenSuccessPopup(true);
  };

  const onFailurPGAData = (res) => {
    setNotSuccessPopup(true);
  };


  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >

          <StyledMenuItem>
            <StyledButton width="180px" onClick={viewPeerGroupAnalysisClientData}>
            View Last PGA
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton width="180px" onClick={generatePeerGroupAnalysisClientData}>
            Update PGA and Scores
            </StyledButton>
          </StyledMenuItem>

        </StyledMenu>

        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
        />

        <AlertMessage
          openPopup={notSuccessPopup}
          setOpenPopup={setNotSuccessPopup}
          description="Please verify the financial and bond data associated with this account."
          isFormatIssue={false}
        />
      </>
    );
  }
};
